import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Loading, Select } from '../../components';
import { DeleteModal } from '../../components';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import PacksModal from './PacksModal';
function Packs() {
    const { t } = useTranslation();

    const { checkAccess } = useAccessChecker();

    const [PackId, setPackId] = useState(null)
    const [searchValue, setSearchValue] = useState(null)

    const [loading, setLoding] = useState(false)
    const [Pack, setPack] = useState([])
    const [done, setDone] = useState(false)
    const [Modal, setModal] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)


    const [formData, setFormData] = useState({ name: null, price: null, features: [], is_popular: null, is_active: null })


    const HandleOnChange = (e) => {
        const { name, value } = e.target;

        const parsedValue = name === "is_active" || name === "is_popular" ? value === "true" : value;

        setFormData({ ...formData, [name]: parsedValue });
    };

    const initializeLinks = () => {
        setFormData({ name: null, price: null, features: [], is_popular: null, is_active: null })
    }

    useEffect(() => {
        getPack()
    }, [PackId, done])


    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    const getPack = async (page = 1) => {
        try {
            setLoding(true)
            const response = await axiosInstance.post(`/pack/search?page=${page}&per_page=10`);
            const responseData = response.data

            if (responseData) {
                setPack(responseData.data)
                setCurrentPage(responseData.current_page);
                setTotalPages(responseData.last_page);

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };
    const handleNext = () => {
        if (currentPage < totalPages) {
            getPack(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            getPack(currentPage - 1);
        }
    };


    const find = async (e) => {
        try {
            e.preventDefault()
            setLoding(true)
            const response = await axiosInstance.post(`/pack/find`, { searchValue });
            const responseData = response.data

            if (responseData) {
                setPack(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const reset = () => {
        setSearchValue('')
        getPack()


    }


    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }


    const HandleeditLink = async (id) => {
        try {
            setLoding(true);

            const response = await axiosInstance.get(`/pack/get/${id}`);
            setFormData(response.data.data);
            setModal(true);
            setPackId(id)
            setLoding(false);

        } catch (error) {
            setLoding(false);
            console.error('Failed to fetch pack:', error.response.data);
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            }} className="flex flex-col w-full justify-start md:p-6 p-2 h-[100vh]">
            {loading &&
                <Loading />
            }
            <section className=" w-full mx-auto">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex  items-center gap-x-3">
                            <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Packs</h2>
                            <span className="px-3 py-1 text-xs dark:bg-secondary/10 dark:text-secondary rounded-full text-primary bg-primary/10">{Pack.length} Pack</span>
                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">Here you can manage the system's promo codes.</p>
                    </div>
                    <div className="flex items-center mt-4 gap-x-3">

                        {checkAccess('packs', 'create') &&

                            <button onClick={() => { setModal(true) }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>Add Pack</span>
                            </button>
                        }
                    </div>
                </div>
                <div className="mt-6 md:flex md:items-center w-full md:justify-between">

                    <form className="flex items-center md:w-1/3 w-full  ">
                        <label htmlFor="simple-search" className="sr-only">Search</label>
                        <div className="relative w-full">
                            <div className="absolute cursor-pointer z-50 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg onClick={(e) => reset(e)} className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2" />
                                </svg>
                            </div>
                            <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} id="simple-search" className="peer h-11 block w-full border bg-neutral-50  border-neutral-200 rounded-lg text-sm  pl-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-200 dark:focus:ring-neutral-600" placeholder="Search branch name..." required />
                        </div>
                        <button type="submit" onClick={(e) => find(e)} disabled={!searchValue} className="h-[2.6rem] w-[2.6rem] flex items-center justify-center ml-2  px-3 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </button>
                    </form>



                </div>
                <div className="flex flex-col mt-6">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden border border-neutral-200 dark:border-neutral-700 md:rounded-lg">
                                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                                    <thead className="bg-neutral-50 dark:bg-neutral-800">
                                        <tr>

                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Name
                                            </th>

                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                price
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                features
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Status
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Popular
                                            </th>
                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Date
                                            </th>
                                            {
                                                (checkAccess('packs', 'update') || checkAccess('packs', 'delete')) &&
                                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">Actions</th>

                                            }

                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200 text-start dark:divide-neutral-700 dark:bg-neutral-900">
                                        {
                                            Pack.length > 0 &&
                                            Pack.map(item => {
                                                return (
                                                    <tr className='h-[4rem]'>
                                                        <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                            <div>
                                                                <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.name}</p>
                                                            </div>
                                                        </td>

                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{item.price}</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{item.features}</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{item.is_active ? 'Active' : 'InActive'}</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{item.is_popular}</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                            <div>
                                                                <p className="text-neutral-500 dark:text-neutral-400">{formatDate(item.created_at)}</p>
                                                            </div>
                                                        </td>
                                                        {
                                                            (checkAccess('packs', 'update') || checkAccess('packs', 'delete')) &&
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div className='flex justify-start items-center gap-2'>
                                                                    {checkAccess('packs', 'delete') &&

                                                                        <a onClick={() => { setPackId(item.id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                                                        </a>
                                                                    }
                                                                    {checkAccess('packs', 'update') &&

                                                                        <a onClick={() => HandleeditLink(item.id)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                                        </a>
                                                                    }

                                                                </div>
                                                            </td>
                                                        }


                                                    </tr>
                                                )
                                            })
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
                    <div className="text-sm text-neutral-500 dark:text-neutral-400">
                        Page <span className="font-medium text-neutral-700 dark:text-neutral-100">{currentPage} of {totalPages}</span>
                    </div>
                    <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                        <button
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>
                            <span>Previous</span>
                        </button>
                        <button
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <span>Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>

            </section>


            <PacksModal Loading={loading} formData={formData} setFormData={setFormData} initializeLinks={initializeLinks} HandleOnChange={HandleOnChange} setModal={setModal} Modal={Modal} setDone={setDone} setLoding={setLoding} done={done} id={PackId} />


            <DeleteModal setDeleteId={setPackId} open={deleteModal} setOpen={setDeleteModal} DeleteId={PackId} Model='pack' setDone={setDone} setLoding={setLoding} done={done} />

        </motion.div >


    )
}

export default Packs