import React, { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/context/AuthContext'
import { toast } from 'react-toastify'
import { Eye, EyeOff, User, Mail, Phone, Lock } from 'lucide-react'
import photo from '../../assets/images/photo.jpg'
import axiosInstance from '../../utils/axios/axiosConfig'
const apiSanctum = process.env.REACT_APP_BACKEND_URL_SANCTUM

function Login() {
  const [view, setView] = useState('signin')
  const [loginData, setLoginData] = useState({ email: null, password: null, code: null })
  const [formData, setFormData] = useState({ doubleAuth: true, email: null, fullName: null, phone: null, role_id: null, etat: null, fonction: null, password: null })
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [codeVerify, setCodeVerify] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const { login } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/Login')
    axios.get(`${apiSanctum}/csrf-cookie`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
  }, [])

  const handleLogin = async (e) => {
    e.preventDefault()
    if (!loginData.email || !loginData.password) {
      setErrorMessage('Both email and password are required.')
      return
    }
    try {
      setErrorMessage('')
      setLoading(true)
      const response = await axiosInstance.post('/auth/login', loginData)
      const responseData = response.data

      if (responseData && responseData.success) {
        setCodeVerify(true)
      } else if (responseData && responseData.user) {
        const { token } = responseData
        login(token)
        navigate('/')
      }
      setLoading(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoading(false)
    }
  }

  const handleCodeVerification = async (e) => {
    e.preventDefault()
    if (!loginData.code) {
      setErrorMessage('The code is required.')
      return
    }
    try {
      setErrorMessage('')
      setLoading(true)
      const response = await axiosInstance.post('/auth/code-verification', loginData)
      const responseData = response.data
      if (responseData && responseData.user) {
        const { token } = responseData
        setCodeVerify(false)
        login(token)
        navigate('/')
      }
      setLoading(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoading(false)
    }
  }

  const handleForgotPassword = async (e) => {
    e.preventDefault()
    try {
      setErrorMessage('')
      setLoading(true)
      const response = await axiosInstance.post('/auth/forget-passKey', loginData)
      const responseData = response.data
      if (responseData) {
        setCodeVerify(true)
      }
      setLoading(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoading(false)
    }
  }

  const handleForgetPassCodeVerification = async (e) => {
    e.preventDefault()
    try {
      setErrorMessage('')
      setLoading(true)
      const response = await axiosInstance.post('/auth/verify-code', loginData)
      const responseData = response.data
      if (responseData) {
        setCodeVerify(false)
        setChangePasswordModal(true)
        setView('signin')
      }
      setLoading(false)
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoading(false)
    }
  }

  const handleSignUp = async (e) => {
    e.preventDefault()
    if (formData.passwordConfirm !== formData.password) {
      setErrorMessage('Confirm password is incorrect.')
      return
    }
    try {
      setLoading(true)
      const response = await axiosInstance.post('/users/register', formData)
      const responseData = response.data
      if (responseData) {
        toast.success(responseData.message)
        setView('signin')
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.05 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.5, ease: [0.4, 0, 0.2, 1] }}
      className="flex h-screen w-screen items-center justify-center overflow-hidden bg-white dark:bg-neutral-900"
    >
      <div className="flex h-full w-full items-center justify-center">
        <div className="hidden md:block md:w-1/2 h-full bg-primary dark:bg-neutral-800 relative">
          <div className="absolute inset-0 bg-cover bg-center"
               style={{ backgroundImage: `url(${photo})`, opacity: 0.7 }}></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-white text-center">
              <h1 className="text-4xl font-bold mb-4">Welcome Back</h1>
              <p className="text-xl">We're glad to see you again!</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-full flex items-center justify-center bg-white dark:bg-neutral-900">
          <div className="w-full max-w-md p-8">
            {view === 'signin' && (
              <SignIn
                loginData={loginData}
                setLoginData={setLoginData}
                loading={loading}
                errorMessage={errorMessage}
                handleLogin={handleLogin}
                setView={setView}
              />
            )}
            {view === 'signup' && (
              <SignUp
                formData={formData}
                setFormData={setFormData}
                loading={loading}
                errorMessage={errorMessage}
                handleSignUp={handleSignUp}
                setView={setView}
              />
            )}
            {view === 'forgot' && (
              <ForgotPassword
                loginData={loginData}
                setLoginData={setLoginData}
                loading={loading}
                errorMessage={errorMessage}
                handleForgotPassword={handleForgotPassword}
                setView={setView}
              />
            )}
            {codeVerify && (
              <VerificationCode
                loginData={loginData}
                setLoginData={setLoginData}
                loading={loading}
                errorMessage={errorMessage}
                handleCodeVerification={view === 'forgot' ? handleForgetPassCodeVerification : handleCodeVerification}
                setView={setView}
              />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

function SignIn({ loginData, setLoginData, loading, errorMessage, handleLogin, setView }) {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <form onSubmit={handleLogin} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Sign In</h1>
        <p className="text-neutral-600 dark:text-neutral-400">Enter your email and password</p>
      </div>

      {errorMessage && (
        <div className="rounded-lg border border-primary bg-primary/10 p-4 text-primary">
          <p className="text-sm font-medium">{errorMessage}</p>
        </div>
      )}

      <div className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Email
          </label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="email"
              type="email"
              placeholder="your@email.com"
              value={loginData.email || ''}
              onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-4 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="password" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Password
          </label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              placeholder="••••••••"
              value={loginData.password || ''}
              onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-10 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
            <button
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400"
            >
              {passwordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full rounded-lg bg-primary px-4 py-2 text-center text-sm font-semibold text-white transition-colors hover:bg-primary/90 focus:outline-none focus:ring disabled:cursor-not-allowed disabled:opacity-50"
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="mr-2 h-4 w-4 animate-spin" viewBox="0 0 24 24"></svg>
            Signing In...
          </span>
        ) : (
          'Sign In'
        )}
      </button>

      <div className="flex items-center justify-between text-sm">
        <button type="button" onClick={() => setView('signup')} className="text-primary hover:underline">
          Create an account
        </button>
        <button type="button" onClick={() => setView('forgot')} className="text-primary hover:underline">
          Forgot password?
        </button>
      </div>
    </form>
  )
}

function SignUp({ formData, setFormData, loading, errorMessage, handleSignUp, setView }) {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <form onSubmit={handleSignUp} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Sign Up</h1>
        <p className="text-neutral-600 dark:text-neutral-400">Create your account to get started</p>
      </div>

      {errorMessage && (
        <div className="rounded-lg border border-primary bg-primary/10 p-4 text-primary">
          <p className="text-sm font-medium">{errorMessage}</p>
        </div>
      )}

      <div className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="fullName" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Full Name
          </label>
          <div className="relative">
            <User className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="fullName"
              type="text"
              placeholder="John Doe"
              value={formData.fullName || ''}
              onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-4 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Email
          </label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="email"
              type="email"
              placeholder="your@email.com"
              value={formData.email || ''}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-4 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="phone" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Phone Number
          </label>
          <div className="relative">
            <Phone className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="phone"
              type="tel"
              placeholder="+1 (555) 000-0000"
              value={formData.phone || ''}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-4 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="password" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Password
          </label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              placeholder="••••••••"
              value={formData.password || ''}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-10 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
            <button
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400"
            >
              {passwordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>

        <div className="space-y-2">
          <label htmlFor="passwordConfirm" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Confirm Password
          </label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="passwordConfirm"
              type={passwordVisible ? 'text' : 'password'}
              placeholder="••••••••"
              value={formData.passwordConfirm || ''}
              onChange={(e) => setFormData({ ...formData, passwordConfirm: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-10 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full rounded-lg bg-primary px-4 py-2 text-center text-sm font-semibold text-white transition-colors hover:bg-primary/90 focus:outline-none focus:ring disabled:cursor-not-allowed disabled:opacity-50"
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="mr-2 h-4 w-4 animate-spin" viewBox="0 0 24 24"></svg>
            Signing Up...
          </span>
        ) : (
          'Sign Up'
        )}
      </button>

      <div className="text-center text-sm">
        <button type="button" onClick={() => setView('signin')} className="text-primary hover:underline">
          Already have an account? Sign In
        </button>
      </div>
    </form>
  )
}

function ForgotPassword({ loginData, setLoginData, loading, errorMessage, handleForgotPassword, setView }) {
  return (
    <form onSubmit={handleForgotPassword} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Forgot Password</h1>
        <p className="text-neutral-600 dark:text-neutral-400">Enter your email to reset your password</p>
      </div>

      {errorMessage && (
        <div className="rounded-lg border border-primary bg-primary/10 p-4 text-primary">
          <p className="text-sm font-medium">{errorMessage}</p>
        </div>
      )}

      <div className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-medium text-left block text-neutral-700 dark:text-neutral-300">
            Email
          </label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-500 dark:text-neutral-400" size={20} />
            <input
              id="email"
              type="email"
              placeholder="your@email.com"
              value={loginData.email || ''}
              onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
              className="w-full rounded-lg border border-neutral-300 bg-white pl-10 pr-4 py-2 text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
              required
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full rounded-lg bg-primary px-4 py-2 text-center text-sm font-semibold text-white transition-colors hover:bg-primary/90 focus:outline-none focus:ring disabled:cursor-not-allowed disabled:opacity-50"
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="mr-2 h-4 w-4 animate-spin" viewBox="0 0 24 24"></svg>
            Sending Reset Link...
          </span>
        ) : (
          'Send Reset Link'
        )}
      </button>

      <div className="text-center text-sm">
        <button type="button" onClick={() => setView('signin')} className="text-primary hover:underline">
          Back to Sign In
        </button>
      </div>
    </form>
  )
}

function VerificationCode({ loginData, setLoginData, loading, errorMessage, handleCodeVerification, setView }) {
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', ''])
  const inputRefs = useRef([])

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
  }, [])

  const handleInput = (e, index) => {
    const value = e.target.value
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newVerificationCode = [...verificationCode]
      newVerificationCode[index] = value
      setVerificationCode(newVerificationCode)
      setLoginData({ ...loginData, code: newVerificationCode.join('') })

      if (value.length === 1 && index < 5) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      inputRefs.current[index - 1].focus()
    }
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const paste = e.clipboardData.getData('text')
    if (paste.length === 6 && /^[0-9]*$/.test(paste)) {
      const newVerificationCode = paste.split('')
      setVerificationCode(newVerificationCode)
      setLoginData({ ...loginData, code: paste })
      inputRefs.current[5].focus()
    }
  }

  return (
    <form onSubmit={handleCodeVerification} className="space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold text-neutral-800 dark:text-neutral-200">Verify Code</h1>
        <p className="text-neutral-600 dark:text-neutral-400">Enter the verification code sent to your email</p>
      </div>

      {errorMessage && (
        <div className="rounded-lg border border-primary bg-primary/10 p-4 text-primary">
          <p className="text-sm font-medium">{errorMessage}</p>
        </div>
      )}

      <div className="flex justify-center space-x-2">
        {verificationCode.map((digit, index) => (
          <input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleInput(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            className="h-12 w-12 rounded-lg border border-neutral-300 bg-white text-center text-lg font-semibold text-neutral-800 focus:border-primary focus:outline-none focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:text-neutral-200 dark:focus:border-primary"
          />
        ))}
      </div>

      <button
        type="submit"
        disabled={loading || verificationCode.join('').length !== 6}
        className="w-full rounded-lg bg-primary px-4 py-2 text-center text-sm font-semibold text-white transition-colors hover:bg-primary/90 focus:outline-none focus:ring disabled:cursor-not-allowed disabled:opacity-50"
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="mr-2 h-4 w-4 animate-spin" viewBox="0 0 24 24"></svg>
            Verifying...
          </span>
        ) : (
          'Verify'
        )}
      </button>

      <div className="text-center text-sm">
        <button type="button" onClick={() => setView('signin')} className="text-primary hover:underline">
          Back to Sign In
        </button>
      </div>
    </form>
  )
}

export default Login

