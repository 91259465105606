import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Loading, SelectVariant, Select, PaymentModal } from '../../components';
import { DeleteModal } from '../../components';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../utils/hooks/useCurrentUser';

function Balance() {
    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = useState({})

    const { checkAccess } = useAccessChecker();

    const [PayementModal, setPaymentModal] = useState(false)
    const [PromoCodeModal, setPromoCodeModal] = useState(false)
    const [promoCode, setPromoCode] = useState(null)
    const [messagePromo, setMessagePromo] = useState(null)

    const [PaymentHistoryList, setPaymentHistoryList] = useState([])



    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [done, setDone] = useState(false)
    const [formData, setFormData] = useState({ name: null, website: null })
    const [loading, setLoding] = useState(false)

    const getCurrentUser = async () => {
        try {
            const response = await axiosInstance.get('/auth/current-user');
            setCurrentUser(response.data.user)
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        getPayementHistory()
        getCurrentUser()

    }, [done])


    const getPayementHistory = async (page = 1) => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/payment/search?page=${page}&per_page=10`);
            const responseData = response.data

            if (responseData) {
                setPaymentHistoryList(responseData.data)
                setCurrentPage(responseData.current_page);
                setTotalPages(responseData.last_page);

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    }

    const handleNext = () => {
        if (currentPage < totalPages) {
            getPayementHistory(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            getPayementHistory(currentPage - 1);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleChangePromoCode = (e) => {
        setPromoCode(e.target.value);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
    }



    const ApplyPromoCode = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            const response = await axiosInstance.post(`/payment/apply-promo-code`, { code: promoCode });
            const responseData = response.data

            if (responseData.success) {
                setPromoCode(null)
                setPromoCodeModal(false)
                toast.success(responseData.message)
                setMessagePromo(null)
            }
            else {
                setMessagePromo(responseData.message)

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }

    }
    return (
        <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
                duration: 0.5,
                ease: [0.4, 0, 0.2, 1],
            }}
            className="flex flex-col w-full justify-start md:p-6 p-2 h-[100vh]">
            {loading &&
                <Loading />
            }
            <section className=" w-full mx-auto">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div>
                        <div className="flex  items-center gap-x-3">
                            <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Balance Overview</h2>

                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">Hre you can manage all account balance .</p>
                    </div>
                    <div className="flex items-center mt-4 gap-x-3">

                        {checkAccess('balances', 'create') &&

                            <button onClick={() => { setPromoCodeModal(true) }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-tags"><path d="m15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19" /><path d="M9.586 5.586A2 2 0 0 0 8.172 5H3a1 1 0 0 0-1 1v5.172a2 2 0 0 0 .586 1.414L8.29 18.29a2.426 2.426 0 0 0 3.42 0l3.58-3.58a2.426 2.426 0 0 0 0-3.42z" /><circle cx="6.5" cy="9.5" r=".5" fill="currentColor" /></svg>
                                <span>Enter Promo Code</span>
                            </button>
                        }

                        {checkAccess('balances', 'create') &&

                            <button onClick={() => { setPaymentModal(true) }} className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 hover:scale-105 dark:bg-neutral-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>Recharge Account</span>
                            </button>
                        }
                    </div>
                </div>



                <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-3">

                    <div className="p-4 flex justify-center items-center w-full bg-white dark:bg-neutral-800 border-gray-200 dark:border-neutral-700 dark:text-neutral-200 text-neutral-800  transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col justify-center items-start space-y-2">
                                <span className="text-neutral-400">Account Balance</span>
                                <span className="text-lg font-semibold">{currentUser?.balance} $</span>
                            </div>
                            <div className="p-3 bg-orange-600 rounded-full" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-7 text-white">
                                    <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
                                    <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clip-rule="evenodd" />
                                </svg>

                            </div>
                        </div>


                    </div>

                    <div className="p-4 flex justify-center items-center w-full bg-white dark:bg-neutral-800 border-gray-200 dark:border-neutral-700 dark:text-neutral-200 text-neutral-800  transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col justify-center items-start space-y-2">
                                <span className="text-neutral-400">Blogs Balance</span>
                                <span className="text-lg font-semibold">{currentUser?.blogs} Blog</span>
                            </div>
                            <div className="p-3 bg-green-600 rounded-full" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-7 text-white">
                                    <path fill-rule="evenodd" d="M7.502 6h7.128A3.375 3.375 0 0 1 18 9.375v9.375a3 3 0 0 0 3-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 0 0-.673-.05A3 3 0 0 0 15 1.5h-1.5a3 3 0 0 0-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6ZM13.5 3A1.5 1.5 0 0 0 12 4.5h4.5A1.5 1.5 0 0 0 15 3h-1.5Z" clip-rule="evenodd" />
                                    <path fill-rule="evenodd" d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V9.375ZM6 12a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V12Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 15a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V15Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75ZM6 18a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H6.75a.75.75 0 0 1-.75-.75V18Zm2.25 0a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                                </svg>


                            </div>
                        </div>


                    </div>


                    <div className="p-4 flex justify-center items-center w-full bg-white dark:bg-neutral-800 border-gray-200 dark:border-neutral-700 dark:text-neutral-200 text-neutral-800  transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col justify-center items-start space-y-2">
                                <span className="text-neutral-400">HyperLinks Balance</span>
                                <span className="text-lg font-semibold">{currentUser?.hyperLinks} HyperLink</span>
                            </div>
                            <div className="p-3 bg-blue-600 rounded-full" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-7 text-white">
                                    <path fill-rule="evenodd" d="M19.902 4.098a3.75 3.75 0 0 0-5.304 0l-4.5 4.5a3.75 3.75 0 0 0 1.035 6.037.75.75 0 0 1-.646 1.353 5.25 5.25 0 0 1-1.449-8.45l4.5-4.5a5.25 5.25 0 1 1 7.424 7.424l-1.757 1.757a.75.75 0 1 1-1.06-1.06l1.757-1.757a3.75 3.75 0 0 0 0-5.304Zm-7.389 4.267a.75.75 0 0 1 1-.353 5.25 5.25 0 0 1 1.449 8.45l-4.5 4.5a5.25 5.25 0 1 1-7.424-7.424l1.757-1.757a.75.75 0 1 1 1.06 1.06l-1.757 1.757a3.75 3.75 0 1 0 5.304 5.304l4.5-4.5a3.75 3.75 0 0 0-1.035-6.037.75.75 0 0 1-.354-1Z" clip-rule="evenodd" />
                                </svg>


                            </div>
                        </div>


                    </div>
                </div>




                <div className="flex flex-col mt-6">
                    <div className='mb-6'>
                        <div className="flex  items-center gap-x-3">
                            <h2 className="text-lg font-medium text-neutral-800 dark:text-white">Balance History</h2>

                        </div>
                        <p className="mt-1 text-start text-sm text-neutral-500 dark:text-neutral-300">Here you will see all the transaction that you made .</p>
                    </div>
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden border border-neutral-200 dark:border-neutral-700 md:rounded-lg">
                                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                                    <thead className="bg-neutral-50 dark:bg-neutral-800">
                                        <tr>
                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Type
                                            </th>

                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Amount
                                            </th>

                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Pack
                                            </th>
                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Discount
                                            </th>

                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Payment Method
                                            </th>

                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-neutral-500 dark:text-neutral-400">
                                                Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200 text-start dark:divide-neutral-700 dark:bg-neutral-900">
                                        {
                                            PaymentHistoryList.length > 0 ?
                                                PaymentHistoryList.map(item => {
                                                    return (
                                                        <tr className='h-[4rem]'>
                                                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                <div>
                                                                    <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.type}</p>
                                                                </div>
                                                            </td>



                                                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                <div>
                                                                    <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.amount}</p>
                                                                </div>
                                                            </td>
                                                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                <div>
                                                                    <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.pack?.name || 'None'}</p>
                                                                </div>
                                                            </td>
                                                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                <div>
                                                                    <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.discount} $</p>
                                                                </div>
                                                            </td>
                                                            <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                <div>
                                                                    <p className="text-sm font-normal text-neutral-600 dark:text-neutral-400">{item.payement_method}</p>
                                                                </div>
                                                            </td>
                                                            <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <div>
                                                                    <p className="text-neutral-500 dark:text-neutral-400">{formatDate(item.created_at)}</p>
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    )
                                                })
                                                :

                                                <tr className="w-full">
                                                    <td colSpan="6" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                                                        {t("There is no balance history to be displayed !!!!")}
                                                    </td>
                                                </tr>
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 sm:flex sm:items-center sm:justify-between ">
                    <div className="text-sm text-neutral-500 dark:text-neutral-400">
                        Page <span className="font-medium text-neutral-700 dark:text-neutral-100">{currentPage} of {totalPages}</span>
                    </div>
                    <div className="flex items-center mt-4 gap-x-4 sm:mt-0">
                        <button
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>
                            <span>Previous</span>
                        </button>
                        <button
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-neutral-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-neutral-100 dark:bg-neutral-900 dark:text-neutral-200 dark:border-neutral-700 dark:hover:bg-neutral-800"
                        >
                            <span>Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                            </svg>
                        </button>
                    </div>
                </div>



                <AnimatePresence>

                    {PromoCodeModal &&
                        <div
                            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                            <div className="absolute bg-black opacity-80 inset-0 z-0" />
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.8 }}
                                transition={{ duration: 0.3 }}
                                className="w-full flex justify-center  items-center flex-col gap-4 md:w-[30%] py-4 px-6 relative mx-auto mx-2 h-fit overflow-y-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">

                                <div className="p-3 bg-orange-100 dark:bg-secondary/10  rounded-full" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-5 dark:text-secondary text-orange-600">
                                        <path fill-rule="evenodd" d="M5.25 2.25a3 3 0 0 0-3 3v4.318a3 3 0 0 0 .879 2.121l9.58 9.581c.92.92 2.39 1.186 3.548.428a18.849 18.849 0 0 0 5.441-5.44c.758-1.16.492-2.629-.428-3.548l-9.58-9.581a3 3 0 0 0-2.122-.879H5.25ZM6.375 7.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" clip-rule="evenodd" />
                                    </svg>
                                </div>

                                <h2 className="text-[20px] font-[700] text-neutral-800 dark:text-white">{t("Apply promoCode")}</h2>
                                <div className='grid gap-1 place-items-start w-full'>
                                    <Input type='text' disabled={false} label={t("Promo Code")} name="promoCode" handleChange={handleChangePromoCode} defaultV={promoCode || null} />
                                    {messagePromo && <p className="text-[11px] text-start font-[400] text-red-600 ">{messagePromo}</p>}

                                </div>

                                <div className='flex justif-center items-center gap-2'>
                                    <button onClick={(e) => ApplyPromoCode(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                        {
                                            !loading ?
                                                (
                                                    <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                        <span>{t("Apply Code")}</span>
                                                    </>
                                                )
                                                :

                                                (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                    <span className="sr-only">Loading...</span>
                                                </div>)

                                        }

                                    </button>


                                    <button onClick={(e) => { setPromoCodeModal(false); setMessagePromo(null) }} className="flex items-center border border-gray-200 dark:border-neutral-700 justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide dark:text-white transition-colors duration-200  text-neutral-700 rounded-lg shrink-0 sm:w-auto gap-x-2  ">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>{t("Close")}</span>


                                    </button>
                                </div>
                            </motion.div>
                        </div>
                    }
                </AnimatePresence >


                <PaymentModal open={PayementModal} setDone={setDone} setOpen={setPaymentModal} />



            </section>
        </motion.div>

    )
}

export default Balance